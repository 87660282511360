import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'SignUpBonusPopup',
  data: function data() {
    return {};
  },
  computed: {
    prize: function prize() {
      var _this$$store$getters$;

      return (_this$$store$getters$ = this.$store.getters['modals/signUpBonusPopup']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.data;
    },
    appMain: function appMain() {
      return this.$store.getters['player/profile'];
    },
    coins: function coins() {
      var _this$prize, _this$appMain, _this$appMain$signUpB;

      return ((_this$prize = this.prize) === null || _this$prize === void 0 ? void 0 : _this$prize.coins) || ((_this$appMain = this.appMain) === null || _this$appMain === void 0 ? void 0 : (_this$appMain$signUpB = _this$appMain.signUpBonus) === null || _this$appMain$signUpB === void 0 ? void 0 : _this$appMain$signUpB.coins);
    },
    entries: function entries() {
      var _this$prize2, _this$appMain2, _this$appMain2$signUp;

      return ((_this$prize2 = this.prize) === null || _this$prize2 === void 0 ? void 0 : _this$prize2.entries) || ((_this$appMain2 = this.appMain) === null || _this$appMain2 === void 0 ? void 0 : (_this$appMain2$signUp = _this$appMain2.signUpBonus) === null || _this$appMain2$signUp === void 0 ? void 0 : _this$appMain2$signUp.entries);
    }
  }
};